import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Text = ({ fields }: TextProps) => (
  <section>
    <div className="container py-5">
      <ParseContent content={fields.description} />
    </div>
  </section>
)

export default Text
