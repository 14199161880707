import React from 'react'
import styled from 'styled-components'
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from 'react-share'

// Images
import Facebook from 'img/facebook-rounded.inline.svg'
import Linkedin from 'img/linkedin-rounded.inline.svg'
import WhatsApp from 'img/whatsapp-rounded.inline.svg'
import Twitter from 'img/twitter-rounded.inline.svg'
import Mail from 'img/mail-rounded.inline.svg'

const ShareContainer = styled.div`
  & svg {
    margin-bottom: 5px;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }
  & h2 {
    color: ${({ theme }) => theme.color.primary} !important;
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media (min-width: 992px) {
    & h2 {
      top: 70px;
      left: 3px;
      transform: rotate(-90deg);
      display: flex;
      white-space: nowrap;
    }
  }
`

interface ShareProps {
  heading: string
  location: any
  title: string
  body: string
  quote?: string
  categories?: Array<any>
  className?: string
}

const Share = ({
  heading,
  location,
  title,
  body,
  quote,
  categories,
  className = '',
}: ShareProps) => {
  const shareUrl = location ? location.href : ''

  const removeHTML = quote?.replace(/<\/?[^>]+(>|$)/g, '')
  const removeQuote = removeHTML?.replace('&#8220;', '"')
  const cleanQuote = removeQuote?.replace('&#8221;', '"')

  return (
    <ShareContainer className={className}>
      <div>
        <LinkedinShareButton
          url={shareUrl}
          title={title}
          summary={body}
          source={shareUrl}
          className="me-2 me-sm-3"
        >
          <Linkedin />
        </LinkedinShareButton>
      </div>
      <div>
        <FacebookShareButton
          url={shareUrl}
          quote={cleanQuote}
          title={title}
          className="me-2 me-sm-3"
        >
          <Facebook />
        </FacebookShareButton>
      </div>
      <div>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          via={shareUrl}
          hashtags={categories}
          className="me-2 me-sm-3"
        >
          <Twitter />
        </TwitterShareButton>
      </div>
      <div>
        <EmailShareButton url={shareUrl} title={title} className="me-2 me-sm-3">
          <Mail />
        </EmailShareButton>
      </div>
      <div>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          className="me-2 me-sm-3"
        >
          <WhatsApp />
        </WhatsappShareButton>
      </div>
      <h2 className="position-relative mb-0 me-4 d-none d-sm-inline-block">
        {heading}
      </h2>
    </ShareContainer>
  )
}

export default Share
