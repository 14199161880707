/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import ParseContent from 'components/shared/ParseContent'
import Block from 'components/elements/Blocks/BlockDefault'
import Share from 'components/elements/Misc/Share'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

// Context
import { VacancyContext } from 'templates/vacancy'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  color: ${({ theme }) => theme.color.primary};

  & .form-duuf-group {
    position: relative;
    margin-bottom: 2.75rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: ${({ theme }) => theme.color.grey};
    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .form-duuf-group-textarea {
    height: 83%;
  }

  & .form-duuf-textarea {
    height: 100%;
    border-radius: 25px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-label {
    margin-bottom: 0.65rem !important;
    margin-left: 1rem !important;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    position: absolute;
    right: 15px;
    top: 5px;
    color: tomato;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0 !important;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 20px !important;
    text-align: center;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const Content = styled(ParseContent)`
  & blockquote {
    border-left: 2px solid ${({ theme }) => theme.color.secondary};
    padding-left: 2rem;
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 30px;
      margin-bottom: 0;
      margin-top: 2rem;
      line-height: 30px;
    }
  }
`

const Main = styled.article`
  color: ${({ theme }) => theme.color.primary};
`

const TextVacancy = ({ fields, location }: TextProps) => {
  const { title } = useContext(VacancyContext)

  return (
    <section style={{ minHeight: '70vh' }}>
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-1">
            <Share
              heading="Deel vacature"
              location={location}
              title="Vacature van GTS Online"
              body={fields.case?.intro || ''}
              className="d-flex flex-row flex-lg-column align-items-center mt-5 pt-5 mb-5 mb-lg-0"
            />
          </div>
          <div className="col-lg-9">
            <Block grey sharp className="py-5 px-sm-5 overflow-hidden">
              <Main className="px-lg-5">
                <Content content={fields?.description} className="pb-5" />
                {fields.blog?.images &&
                  fields.blog?.images?.map((image, index) => (
                    <React.Fragment key={index}>
                      <ParseContent
                        content={image?.image?.description}
                        className="my-4"
                      />
                      <Plaatjie image={image?.image} alt="" className="mb-4" />
                    </React.Fragment>
                  ))}
              </Main>
              {/* @ts-ignore */}
              <StyledFormDuuf
                id={3}
                generate={false}
                defaultFields={{ input_9: title || '' }}
              >
                <h2 className="my-5 font-weight-bold">Solliciteer direct</h2>
                <div className="row">
                  <div className="col-lg-6">
                    <FormDuufGroup hideIds={[2]} />
                  </div>
                  <div className="col-lg-6">
                    <FormDuufGroup showIds={[2]} />
                  </div>
                  <div className="mt-5 mt-lg-0">
                    <div className="d-flex justify-content-center">
                      <FormDuufSubmit />
                    </div>
                  </div>
                </div>
              </StyledFormDuuf>
            </Block>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextVacancy
