import React from 'react'
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import Block from 'components/elements/Blocks/BlockDefault'
import Circle from 'components/elements/Vectors/Circle'

// Images
import Check from 'img/check.svg'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Section = styled.section`
  color: ${({ theme }) => theme.color.primary};
  @media (min-width: 992px) {
    margin-bottom: 3rem;
  }
  @media (max-width: 991px) {
    margin-bottom: 6rem;
  }
`

const Content = styled(ParseContent)`
  & ul {
    list-style: none;
    padding-left: 0;

    & li {
      display: flex;
      margin-bottom: 0.75rem;

      strong {
        color: ${({ theme }) => theme.color.primary};
      }

      &:before {
        width: 27px;
        height: 27px;
        margin-right: 1rem;
        content: url(${Check});
      }
    }
  }
`

const Image = styled(Plaatjie) <{ imagealignment: string }>`
  @media (min-width: 992px) {
    width: 482px;
    height: 482px;
    right: -241px;
    bottom: -50px;
  }

  @media (max-width: 991px) {
    width: 182px;
    height: 182px;
    bottom: -150px;
    ${(props) =>
    props.imagealignment === 'left'
      ? css`
            left: 0;
          `
      : css`
            right: 0;
          `}
  }
`

const TextImage = ({ fields }: TextProps) => (
  <Section className="position-relative pb-lg-5">
    <Circle
      absolute
      right={4}
      top={-2}
      width={168}
      height={168}
      identifier="advantages-detail"
      strokeWidth={15}
    />
    <div className="container py-5">
      <div className="row">
        <div className="position-relative col-lg-10">
          <Block grey className="py-5 px-3 p-lg-5">
            <div className="px-lg-5 py-lg-4">
              <div className="pe-lg-5">
                <div className="pe-lg-5">
                  <div className="pe-lg-5">
                    <Content content={fields.description} />
                  </div>
                </div>
              </div>
            </div>
          </Block>
          <Image
            image={fields.image}
            imagealignment={fields.imagealignment || 'left'}
            alt=""
            className="position-absolute rounded-circle"
          />
        </div>
      </div>
    </div>
  </Section>
)

export default TextImage
