import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import Share from 'components/elements/Misc/Share'
import Block from 'components/elements/Blocks/BlockDefault'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 30px;
      margin-bottom: 0;
      margin-top: 2rem;
    }
  }
`

const Quote = styled(ParseContent)`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};

  @media (min-width: 992px) {
    & p {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0px;
    bottom: 0px;
    width: 96px;
    background-color: ${({ theme }) => theme.color.primary};
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 96px;
    background-color: ${({ theme }) => theme.color.primary};
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
`

const Main = styled.article`
  color: ${({ theme }) => theme.color.primary};
`

const Cta = styled.div``

const CtaDescription = styled(ParseContent)`
  @media (min-width: 992px) {
    & p {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }
`

const Image = styled(Plaatjie)`
  width: 140px;
  height: 140px;
`

const TextCase = ({ fields, location }: TextProps) => (
  <section className="mb-5">
    <div className="container">
      <div className="row justify-content-end">
        <div className="col-lg-1">
          <Share
            heading="Deel deze case"
            location={location}
            title="Case van GTS Online"
            body={fields.case?.intro || ''}
            className="d-flex flex-row flex-lg-column align-items-center mt-5 pt-5 mb-5 mb-lg-0"
          />
        </div>
        <div className="col-lg-9">
          <Block
            grey
            sharp
            className="py-5 px-sm-5 overflow-hidden"
            style={{ borderBottomLeftRadius: 50, borderBottomRightRadius: 50 }}
          >
            <Main className="px-lg-5">
              <Content content={fields.case?.intro} className="pb-5" />
              <Quote
                content={fields.case?.quote}
                className="position-relative py-3 py-sm-5 px-3 px-sm-0"
              />
              <Content content={fields.case?.body} className="pt-5" />
              <Cta className="d-flex flex-column flex-md-row justify-content-around align-items-center px-xl-5 my-5 py-5">
                <Image
                  image={fields.case?.cta?.image}
                  alt=""
                  className="rounded-circle"
                />
                <CtaDescription
                  content={fields.case?.cta?.description}
                  className="text-center text-md-start"
                />
              </Cta>
              <Content content={fields.case?.outro} />
            </Main>
          </Block>
        </div>
      </div>
    </div>
  </section>
)

export default TextCase
