import React from 'react'

// Components
import Text from 'components/flex/Text/Text'
import TextImage from 'components/flex/Text/TextImage'
import TextCase from 'components/flex/Text/TextCase'
import TextBlog from 'components/flex/Text/TextBlog'
import TextVacancy from './TextVacancy'

export interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
}

interface PostProps {
  [key: string]: any
}

const TextShell = ({ fields, location = {} }: TextProps) => {
  const types: PostProps = {
    default: Text,
    image: TextImage,
    case: TextCase,
    blog: TextBlog,
    vacancy: TextVacancy,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default TextShell
